export const environment = {
  production: false,
  staging: true,
  development: false,
  pwaEnabled: true,
  appBaseUrl: 'https://tms-staging.jangkarpacific.com',
  authBaseUrl: 'https://uam-staging.jangkarpacific.com',
  loginBaseUrl: 'https://apps-staging.jangkarpacific.com',
  xDebugSessionKey: '',
};
